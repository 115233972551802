import React, { Component } from "react"
import { graphql } from 'gatsby'

import Layout from '../../../components/theme/Layout'
import Footer from '../../../components/theme/Footer'
import BlogListing from '../../../components/pages/BlogListing'

class HomeSolutions extends Component {
  render() {
    const { location } = this.props
    const { data } = this.props
    return (
      <Layout
        meta={data.defaultPage.meta}
        urls={data.defaultPage.urls}
        locale='en'
      >
        <BlogListing
          image={data.defaultPage.image_url}
          title={data.defaultPage.title_html}
          subtitle={data.defaultPage.subtitle}
          alt={data.defaultPage.title}
          location={location}
          locale='en'
          urls={data.defaultPage.urls}
        />
        <Footer />
      </Layout>
    )
  }
}

export const query = graphql`
query {
  defaultPage(guid: { eq: "85fb8531-f0f6-4216-98ee-53c2e0e87b69" }, locale: {eq:"en"}) {
    title_html
    title
    subtitle
    description
    image_url
    urls {
      en
      fr
      zh_hant
      zh_hans
    }
    meta {
      description
      keywords
      title
    }
  }
}
`

export default HomeSolutions
